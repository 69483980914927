import {get,post,del,put} from '../http/http'
import baseUrl, { indexUrl } from "../http/baseUrl";

//专利超市首页楼层
export const getPatentIndex = (params) => {
  return get(`${indexUrl}/v1/template/patent`,params, { hideLoading: true })
}



//专利列表
export const getGoodsList=(params)=> {
  return get(`${baseUrl}/v1/goods`,params, {hideLoading: true});
}

//商品列表分类
export const getClassify=(params)=> {
  return get(`${baseUrl}/v1/classify/all`,params, {hideLoading: true});
}

// 商品详情
export const getGoodDetail=(params)=> {
  return get(`${baseUrl}/v1/goods/one`,params, {hideLoading: true});
}
//评估报告
export const getReport=(params)=> {
  return get(`${baseUrl}/v1/goods/patent/report`,params, {hideLoading: true});
}
//相似度
export const getsimilar=(params)=> {
  return get(`${baseUrl}/v1/goods/patent/similar/findbyan`,params, {hideLoading: true});
}
// 引证
export const getquote=(params)=> {
  return get(`${baseUrl}/v1/goods/patent/quote/findbyan`,params, {hideLoading: true});
}
//发送咨询
export const sendComment=(data)=> {
  return post(`${baseUrl}/v1/goods/comment/save`,data, {hideLoading: true});
}
//咨询列表
export const getComment=(params)=>{
  return get(`${baseUrl}/v1/goods/comm/list`,params, {hideLoading: true});
}


export const goodsRest=(params) => {
  return get(`${baseUrl}/v1/order/goodsInventoryVerify`,params, {hideLoading: true});
}