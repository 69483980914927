<template>
  <!--  &lt;!&ndash;高校&ndash;&gt;-->
  <!--  <div class="center" style=";overflow: hidden;margin-top: 15px;font-size: 14px">-->
  <!--    <div style="float: left;margin-right: 15px">-->
  <!--      <div style="width: 220px;background-color: white">-->
  <!--        <div style="min-height: 230px;text-align: center">-->
  <!--          <Avatar v-if="!shopDetal.imageUrl" icon="ios-person" size="70" style="margin: 30px auto"/>-->
  <!--          <img v-else :src="shopDetal.imageUrl" style="width: 100px;height: 90px" alt="">-->
  <!--          <div style="margin-bottom: 30px">-->
  <!--            <span style="font-size: 18px;color: black">{{shopDetal.name}}</span>-->
  <!--          </div>-->
  <!--          <div @click="returnRoom()"-->
  <!--               style="height: 30px;width: 80px;margin: 20px auto;cursor: pointer;line-height: 30px;background-color: #1890FF;color: white">-->
  <!--            咨询-->
  <!--          </div>-->
  <!--        </div>-->
  <!--        <div style="padding: 0 15px">-->
  <!--          <div style="margin-bottom: 15px"><span style="font-size: 14px;color: black">店铺简介:</span></div>-->
  <!--          <div>-->
  <!--                <span>-->
  <!--                  {{shopDetal.introduction || '暂无信息'}}-->
  <!--                </span>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div style="margin-top: 15px" v-if="commentList.length > 0">-->
  <!--        <div-->
  <!--            style="height: 40px;border-bottom: 1px solid rgb(232, 232, 232);line-height: 40px;background-color: white;padding:0 15px;">-->
  <!--          <span style="font-size: 16px">店铺答疑</span>-->
  <!--        </div>-->
  <!--        <div v-for="(item,index) in commentList" :key="index"-->
  <!--             style="padding:20px 15px;width:220px;background-color: white;border-bottom: 1px solid rgb(232, 232, 232)">-->
  <!--          <div><span style="font-size: 14px;color: black">{{item.reviewContent}}?</span></div>-->
  <!--          <div><span>-->
  <!--                {{item.replyContent}}-->
  <!--              </span></div>-->
  <!--        </div>-->
  <!--        <div-->
  <!--            style="line-height:54px;height: 54px;width: 220px;padding:0 15px;background-color: white;;text-align: right">-->
  <!--              <span @click="lastpre()"-->
  <!--                    style="line-height:30px;text-align:center;color:black;display: inline-block;height: 30px;width: 30px;background-color:#F1F2F6;border-radius: 2px;cursor: pointer"><</span>-->
  <!--          <span @click="nextpre()"-->
  <!--                style="line-height:30px;text-align:center;color:black;display: inline-block;height: 30px;width: 30px;background-color:#F1F2F6;border-radius: 2px ;cursor: pointer">></span>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div style="float: left;width: 965px">-->
  <!--      &lt;!&ndash;      店铺专利&ndash;&gt;-->
  <!--      <div v-if="patentList.length" style="margin-bottom: 40px">-->
  <!--        <div>-->
  <!--          <div style="height: 50px;line-height: 50px;padding: 0 10px;background-color: white">-->
  <!--            <div style="float: left">-->
  <!--              <span>店铺专利</span>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--        <div style="display: flex;flex-wrap: wrap;justify-content: flex-start;">-->

  <!--          <div class="buy_now" style="height: 380px;background-color:white;width: 230px;margin: 10px 5px 0"-->
  <!--               v-for="(item,index) in patentList" :key="index" @click.stop="seeDetail(item.id)">-->
  <!--            <div style="height: 200px">-->
  <!--              <img :src="item.mainImageUrl" style="height: 200px;width: 230px" alt="">-->
  <!--            </div>-->
  <!--            <div style="height: 180px;padding: 0 10px">-->
  <!--              <div style="height: 60px;line-height: 60px">-->
  <!--                    <span style="font-size: 20px;color: rgb(255, 106, 0);-->
  <!--">￥{{item.price}}</span>-->
  <!--              </div>-->
  <!--              <div style="height: 40px">-->
  <!--                  <span style="font-size: 14px;line-height: 20px;color: black;" class="liang_hang_sheng">-->
  <!--                    <div class="html-box">{{item.name}}</div>-->
  <!--                  </span>-->
  <!--              </div>-->
  <!--              <div style="font-size: 12px;margin: 10px 0;overflow: hidden;height: 18px">-->
  <!--                <div style="float: left"><span>{{item.shopName}}</span></div>-->
  <!--                <div style="float: right;color: rgb(255, 106, 0);-->
  <!--">{{item.patentType}}-->
  <!--                </div>-->
  <!--              </div>-->

  <!--              <div style="font-size: 12px;margin: 20px 0;overflow: hidden;display: flex;justify-content: space-between">-->
  <!--                <div class="msgbtn" @click.stop="openZoosUrl()">-->
  <!--                  立即咨询-->
  <!--                </div>-->
  <!--                <div class="msgbtn" @click.stop="goBuy(item)" style="-->
  <!--    border: 1px solid #ff6a00;-->
  <!--    color: #ff6a00;">-->
  <!--                  立即购买-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--        <div style="height: 70px">-->
  <!--          <Page :total="patenttotalNum" :page-size="shopparams.pageSize" show-elevator-->
  <!--                style="margin-top: 20px;float: right;" @on-change="changefPage"/>-->
  <!--        </div>-->

  <!--      </div>-->
  <!--      &lt;!&ndash;      成果图片&ndash;&gt;-->
  <!--      <div v-if="serviceList.length" style="margin-bottom: 40px">-->
  <!--        <div>-->
  <!--          <div style="height: 50px;line-height: 50px;padding: 0 10px;background-color: white">-->
  <!--            <div style="float: left">-->
  <!--              <span>店铺服务</span>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->

  <!--        <div style="display: flex;flex-wrap: wrap;justify-content: flex-start;">-->
  <!--          <div class="buy_now" style="height: 380px;background-color:white;width: 230px;margin: 10px 5px 0"-->
  <!--               v-for="(item,index) in serviceList" :key="index" @click.stop="seeDetail1(item)">-->
  <!--            <div style="height: 200px">-->
  <!--              <img v-if="item.mainImageUrl" :src="item.mainImageUrl" style="height: 200px;width: 230px" alt="">-->
  <!--              <img v-else src="~@/assets/image/patent/patent-default.png" style="height: 200px;width: 230px" alt="">-->
  <!--            </div>-->
  <!--            <div style="height: 180px;padding: 0 10px">-->
  <!--              <div style="height: 70px;padding: 20px 0 10px 0">-->
  <!--                  <span style="font-size: 14px;line-height: 20px;color: black;" class="liang_hang_sheng">-->
  <!--                    <div v-html="item.name" class="html-box"></div>-->
  <!--                  </span>-->
  <!--              </div>-->
  <!--              <div style="margin-bottom: 7px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;padding-right: 10px">-->
  <!--                <span style="font-size:12px;padding-top: 5px">{{item.labelValue}}</span>-->
  <!--              </div>-->
  <!--              <div style="font-size: 12px;margin: 10px 0;overflow: hidden">-->
  <!--                <div style="float: left"><span>{{item.shopName}}</span></div>-->
  <!--                &lt;!&ndash;                <div style="float: right;color: rgb(255, 106, 0);&ndash;&gt;-->
  <!--                &lt;!&ndash;">{{item.patentType}}&ndash;&gt;-->
  <!--                &lt;!&ndash;                </div>&ndash;&gt;-->
  <!--              </div>-->

  <!--              <div style="font-size: 12px;margin: 20px 0;overflow: hidden;display: flex;justify-content: space-between">-->
  <!--                <div class="msgbtn" @click.stop="openZoosUrl">-->
  <!--                  立即咨询-->
  <!--                </div>-->
  <!--                <div class="msgbtn" @click.stop="seeDetail2(item)" style="-->
  <!--    border: 1px solid #1890FF;-->
  <!--    color: #1890FF;">-->
  <!--                  立即购买-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--        <div style="height: 70px">-->
  <!--          <Page :total="servicetotalNum" :page-size="serviceparams.pageSize" show-elevator-->
  <!--                style="margin-top: 20px;float: right;" @on-change="changetPage"/>-->
  <!--        </div>-->

  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  <div>
    <div class="store-head">
      <div class="sh-body">
        <Avatar v-if="!shopDetal.imageUrl" icon="ios-person" size="70" style="margin: 30px auto"/>
        <img v-else :src="shopDetal.imageUrl" class="shb-icon" alt="">
        <div class="shb-des">
          <div class="shb-des-title">
            <div class="shb-des-title-text ellipse-1">{{ shopDetal.name }}</div>
            <div class="shb-des-title-tag display-flex-center">已认证</div>
          </div>
          <div class="shb-des-tel">电话：{{ shopDetal.contactPhone ? shopDetal.contactPhone : '暂无' }}</div>
          <div class="shb-des-tel">地址：暂无</div>
          <div class="shb-rate">
            <div>
              <span style="margin-right: 5px">服务质量</span>
              <Rate disabled :value="shopDetal.serviceQuality||5"/>
            </div>
            <div style="margin-left: 20px">
              <span style="margin-right: 5px">服务态度</span>
              <Rate disabled :value="shopDetal.serviceAttitude||5"/>
            </div>
            <div style="margin-left: 20px">
              <span style="margin-right: 5px">响应速度</span>
              <Rate disabled :value="shopDetal.serviceSpeed||5"/>
            </div>
          </div>
        </div>
        <div class="shb-num">商品数：<span style="color: rgba(254, 121, 48, 1)">{{ shopDetal.goodsCount || 0 }}</span>件
        </div>
        <div class="shb-right">
          <img
              src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/51miz-E769910-F2561733%402x.png"
              class="shb-right-img"/>
          <div class="shb-right-button display-flex-center" @click="returnRoom">立即咨询</div>
        </div>
      </div>
      <div class="sh-bottom">
          <img src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_%E5%BA%97%E9%93%BA%E7%AE%80%E4%BB%8B%402x.png"
          class="sh-bottom-left"
          />
        <div class="sh-bottom-blank" />
        <div class="sh-bottom-right ellipse-2">
          {{shopDetal.introduction || '暂无简介'}}
        </div>
      </div>
    </div>
    <div class="store-list">
      <div class="sl-body" v-for="(item,index) in serviceList" :key="index" @click.stop="seeDetail1(item)">
        <img v-if="item.mainImageUrl" :src="item.mainImageUrl" class="sl-img" alt="" />
        <img v-else src="~@/assets/image/patent/patent-default.png" class="sl-img" alt="" />
        <div class="sl-center">
          <div v-html="item.name" class="ellipse-1" />
          <div class="sl-tag">
            <div class="sl-tagA display-flex-center">{{item.patentType}}</div>
            <div class="sl-tagB display-flex-center">标准</div>
          </div>
        </div>
        <div class="sl-price">服务价格：<span style="color:rgba(254, 121, 48, 1);font-size: 24px">¥{{item.price}}</span></div>
        <div class="sl-button">
          <div class="sl-buttonA display-flex-center" @click.stop="openZoosUrl">立即咨询</div>
          <div class="sl-buttonB display-flex-center" @click.stop="seeDetail2(item)">立即购买</div>
        </div>
      </div>
      <div style="display: flex;flex-direction: row-reverse;padding: 40px 20px">
        <Page :total="servicetotalNum" :page-size="serviceparams.pageSize" show-elevator
              style="margin-top: 20px;float: right;" @on-change="changetPage"/>
      </div>

    </div>
  </div>

</template>

<script>
import {goodsRest} from "@/plugins/api/Good";
import {
  getOutsideStoreDetail,
  shopCommentList,
  sendComment
} from "@/plugins/api/Shop";
import {getGoodsList} from "@/plugins/api/Good"
import {getScienceServiceList} from "@/plugins/api/scienceServiceApi"
import {checkLogin} from "@/plugins/api/loginApi";
import {successToast} from "@/plugins/tools/util";
import {errorToast} from "@/plugins/tools/util";

export default {
  name: "ServiceOutSideList",
  data() {
    return {
      shopId: '',
      shopparams: {
        pageSize: 8,
        pageNum: 1,
      },
      patentList: [],
      patenttotalNum: 0,
      shopDetal: [],
      servicetotalNum: 0,
      serviceparams: {
        pageNum: 1,
        pageSize: 4,
        college: ''
      },
      serviceList: [],
      commentparams: {
        pageNum: 1,
        pageSize: 8,
      },
      commentList: [],
      commentListTotal: 0
    }
  },
  watch: {
    $route: {
      immediate: true, // 一旦监听到路由的变化立即执行
      handler(to, from) {
        if (JSON.stringify(to.params) !== '{}') {
          if (!to.query.shopId) {
            this.$router.push({name: 'index'})
          }
          this.shopId = to.query.shopId
          this.getpatentGoods()
          this.getScienceServiceList()
          this.getOutsideStoreDetail()
          this.shopCommentList()
        }
      },
    }
  },
  methods: {
    //购买
    async goBuy(item) {
      let json = await goodsRest({goodsId: item.id, num: 1})
      if (json.code == 0) {
        if (json.result == true) {
          this.$router.push({
            path: '/home/good',
            query: {
              goodId: item.id,
              action: 1
            }
          })
        }
      } else {
        errorToast(json.message)
      }

    },

    seeDetail(id) {
      this.$router.push({
        path: "/home/good",
        query: {
          goodId: id
        }
      })
    },
    // 成果
    async seeDetail2(item) {
      let json = await goodsRest({goodsId: item.id, num: 1})
      if (json.code == 0) {
        if (json.result == true) {
          let routeUrl = this.$router.resolve({
            path: "/home/servicePay",//新页面地址
            query: {goodId: item.id}//携带的参数
          });
          window.open(routeUrl.href, "_blank");
        }
      } else {
        errorToast(json.message)
      }

    },
    seeDetail1(item) {
      this.$router.push({
        path: "/home/serviceDetail",
        query: {
          goodId: item.id
        }
      })

    },
    //在线咨询
    openZoosUrl() {
      openZoosUrl('chatwin');
    },
    getpatentGoods() {
      getGoodsList({
        shopId: this.shopId,
        ...this.shopparams
      }).then((res) => {
        if (res.code === 0) {
          this.patentList = res.result.list
          this.patenttotalNum = res.result.total
        }
      })
    },
    changetPage(index) {
      this.serviceparams.pageNum = index;
      this.getScienceServiceList()
    },
    //高校详情
    getOutsideStoreDetail() {
      getOutsideStoreDetail({
        shopId: this.shopId
      }).then((res) => {
        if (res.code === 0) {
          this.shopDetal = res.result
          this.serviceparams.college = res.result.name
        }
      })
    },
    // 翻页
    changefPage(index) {
      this.shopparams.pageNum = index;
      this.getpatentGoods();
    },
    //咨询弹窗
    returnRoom: function () {
      const state = checkLogin(this.$router)
      if (!state) {
        return
      }

      this.$Modal.confirm({
        draggable: true,
        onOk: async () => {
          this.sendComment()
        },
        render: (h) => {
          return h('div',
              [h('div', {
                style: "margin-bottom:20px;vertical-align: middle;font-size: 16px;color: #17233d;font-weight: 700;",
              }, '咨询内容'),
                h('Input', {
                  style: "width:100%;",
                  props: {
                    value: this.textareaVal,
                    autofocus: true,
                    placeholder: '请输入咨询内容',
                    type: 'textarea',
                    autosize: true
                  },
                  on: {
                    input: (val) => {
                      this.textareaVal = val;
                    }
                  }
                })
              ])
        }
      })
    },
    //咨询列表
    shopCommentList() {
      shopCommentList({shopId: this.shopId, ...this.commentparams}).then(res => {
        if (res.code === 0) {
          this.commentList = res.result.list;
          this.commentListTotal = res.result.total;
        }
      })
    },
    //发送咨询
    sendComment() {
      sendComment({
        shopId: this.shopId,
        reviewContent: this.textareaVal
      }).then(res => {
        if (res.code === 0)
          this.textareaVal = ""
        successToast("咨询成功")
      })
    },
    //咨询上一页
    lastpre() {
      if (this.commentparams.pageNum > 1) {
        this.commentparams.pageNum = this.commentparams.pageNum - 1
        this.shopCommentList()
      }
    },
    //咨询下一页
    nextpre() {
      if (this.commentparams.pageNum * this.commentparams.pageSize < this.commentListTotal) {
        this.commentparams.pageNum = this.commentparams.pageNum - 0 + 1
        this.shopCommentList()
      }
    },
    //服务商品
    getScienceServiceList() {
      getScienceServiceList({shopId: this.shopId, ...this.serviceparams}).then(res => {
        if (res.code == 0) {
          this.servicetotalNum = res.result.total
          this.serviceList = res.result.list

        }
      })
    }

  }
}
</script>

<style scoped>
.store-head {
  width: 1200px;
  height: 243px;
  background: linear-gradient(180deg, rgba(42, 139, 239, 0.29), rgba(255, 255, 255, 0));
  border-radius: 6px;
  box-shadow: 1px 1px 10px rgba(229, 239, 249, 1);
  margin: 30px auto 20px auto;
}

.sh-body {
  height: 180px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(228, 228, 228, 1);
  padding: 29px 37px 29px 20px;
}

.sh-bottom{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 63px;
}

.sh-bottom-left{
  width: 81px;
  height: 18px;
  margin-left: 20px;
}

.sh-bottom-blank{
  width: 1px;
  height: 69px;
  margin-left: 37px;
  background: linear-gradient(0deg, #000000 0%, #FFFFFF 0%, #FFFFFF 0%, #C6DFFF 47%, #FFFFFF 100%);
}

.sh-bottom-right{
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  padding-left: 40px;
  padding-right: 80px;
}

.shb-icon {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}

.shb-des {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 28px;
  overflow: hidden;
  width: 643px;
}

.shb-des-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.shb-des-title-text {
  font-size: 22px;
  font-weight: bold;
  color: #333333;
}

.shb-des-title-tag {
  width: 60px;
  height: 22px;
  background: #D7F1D9;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #17C22D;
  flex-shrink: 0;
  margin-left: 10px;
}

.shb-des-tel {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
}

.shb-rate {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

.shb-num {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 70px;
  flex-shrink: 0;
}

.shb-right {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  align-items: flex-end;
  margin-left: 50px;
  position: relative;
}


.shb-right-img {
  width: 121px;
  height: 128px;
}

.shb-right-button {
  position: absolute;
  top: 23px;
  right: -20px;
  width: 120px;
  height: 34px;
  background: #DCEAFE;
  border: 1px solid #0F62DF;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #0F62DF;
  cursor: pointer;
}

.store-list {
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 10px rgba(229, 239, 249, 1);
  width: 1200px;
  border-radius: 6px;
  margin: 0 auto;
}

.sl-body {
  height: 182px;
  border-bottom: 1px solid rgba(228, 228, 228, 1);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.sl-body:hover {
  box-shadow: 1px 1px 10px rgba(15, 98, 223, 1);
}

.sl-img {
  width: 214px;
  height: 120px;
  margin-left: 20px;
  object-fit: cover;
}

.sl-center{
  display: flex;
  flex-direction: column;
  width: 590px;
  padding-left: 20px;
  font-size: 20px;
  font-weight: 400;
  color: #333333;
}

.sl-tag{
  display: flex;
  flex-direction: row;
  margin-top: 18px;
}

.sl-tagA{
  width: 82px;
  height: 26px;
  background: #FFDBCD;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #FE7930;
}

.sl-tagB{
  width: 58px;
  height: 26px;
  background: #CADFFE;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #0F62DF;
  margin-left: 10px;
}

.sl-price{
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}

.sl-button{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  align-items: flex-end;
  padding-right: 37px;
}

.sl-buttonA{
  width: 120px;
  height: 34px;
  background: #DCEAFE;
  border: 1px solid #0F62DF;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #0F62DF;
  cursor: pointer;
}

.sl-buttonB{
  width: 120px;
  height: 34px;
  background: #FE7930;
  border: 1px solid #FE7930;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
}

</style>


<style>
textarea.ivu-input {
  height: 120px !important;
}
</style>
